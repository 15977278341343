import { LitElement, html, css } from 'lit';
import { RandomVariableSet } from './random-variables';
import './question-elelement';
export class WorksheetElement extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
                border: 1px solid black;
                padding: 10px;
            }
            @counter-style cs-numeric {
                system: extends decimal;
                suffix: ")";
            }
            ol.question-list {
                list-style: cs-numeric;
            }
        `
    ];//a b c d e f g h i j k l m n o p q r s t u v w x y z;


    static properties = {
        question : { type: String },
        variables : { type: Object },
        composite_variables : { type: Array },
        count : { type: Number },
        placeholder_keys : { type: Array },
        forPrint : { type: Boolean },
        title : { type: String }

    };

    constructor() {
        super();
        this.question = '';
        this.title = 'Worksheet';
        this.variables = {};
        this.composite_variables= [];
        this.placeholder_keys = [];
        this.count = 1;
        this.forPrint = false;
        this.random_set = new RandomVariableSet();
    }


    

    render() {
        this.random_set.createVariableSet( this.variables, this.composite_variables );
        const random_result = this.random_set.randomize( this.count );
        
        return html`
            <h2>${this.title}</h2>
            <ol class="question-list">
                ${random_result.map( (item) => {
                    return html`<li>
                        <question-elelement ?forPrint='${this.forPrint}'
                            .variables='${item}' .placeholder_keys='${this.placeholder_keys}' .placeholder='${this.question}' >
                        </question-elelement>
                    </li>`;
                })}
            </ol>
        `;
    }
}
customElements.define('worksheet-element', WorksheetElement);
