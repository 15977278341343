import { html, css } from 'lit';
import BaseInput from './base-input';

export class ShortInput extends BaseInput {
    static styles = [
        BaseInput.styles,
        css`
            :host {
                display: block;
            }
        `
    ];
    static properties = {

    };

    constructor() {
        super();
        
    }

    handleInput(event) {
        this.input_value = event.target.value;
        
    }

    getInput() {
        return html`
            <input type="text" id="${this.input_id}" name="input" value="${this.input_value}" @input="${this.handleInput}">
        `;
    }
}
customElements.define('short-input', ShortInput);