import { html, css } from 'lit';
import BaseInput from './base-input';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

export class ChoiceInput extends BaseInput {
    static styles = [
        BaseInput.styles, css`
            :host {
                display: block;
                border: 1px solid #ccc;
            }
            ul.choices{
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
            ul.choices li{
                margin-bottom: 10px;
            }
        `
    ];
    static properties = {
        choices : { type: Array },
        choiceType : { type: String }//TF (True of False), MC ( Multiple Choice ), MS ( Multiple Select ), MA ()
    };

    constructor() {
        super();
        this.choiceType = 'TF';
        this.choices = [{ html : 'True', id : 'true' }, { html : 'False', id : 'false'}];
    }
    
    getInput() {//radio checkbox
        return html`
            <ul class='choices'>
                ${this.choices.map((choice) => html`
                    <li>
                        <input type="${this.choiceType === 'MS' ? 'checkbox' : 'radio'}" id="${choice.id}" name="${this.input_id}" value="${choice}"/>
                        <label for="${choice.id}">${unsafeHTML(choice.html)}</label>
                    </li>
                `)}
            </ul>
        `;
    }

    save(){
        this.shadowRoot.querySelectorAll(`input[name='${this.input_id}']`).forEach((input) => {
            const choice = this.choices.find((choice) => choice.id === input.id);
            if(input.checked){
                this.input_value = input.value;
            }
        });
    }
}
customElements.define('choice-input', ChoiceInput);
