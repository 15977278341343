import { LitElement,html, css } from 'lit';
//import 'content/content-math';
import {ContentElement} from './content-element';
import './worksheet-element';
import '../inputs/math-input';
import './latex-image';

//?checked=${checked}


const test_content = html`
<worksheet-element></worksheet-element>
<section>
    Hello World 
</section>
<section>
    hello world
    <latex-image></latex-image>
    <content-math exp="x=\\underline{\\hskip2em}"></content-math> Some more stuff
    <content-math chem exp="\\ce{CO2 + C -> 2 CO}"></content-math>
    <br/>
    <content-math exp="3\\overline{\\smash{)}123}"></content-math>
    <math-input placeholder_keys='["a","z"]' placeholder=' a (x+1)(x-1) = z'></math-input>
    <input-test></input-test>
</section>
`;
const parseDOM = (rootElement) => { 
    for(let el of rootElement.children) { 
        if (el.shadowRoot) { 
            parseDOM(el.shadowRoot); 
            el.innerHTML += getShadowDOMHtml(el.shadowRoot); 
        }
    } 
    
    return rootElement.innerHTML; 
}; 
const getShadowDOMHtml = (shadowRoot) => { 
    let shadowHTML = ''; 
    for (let el of shadowRoot.children) { 
        if (el.shadowRoot) {
            el.innerHTML += getShadowDOMHtml(el.shadowRoot);
        }else{
            shadowHTML += el.nodeValue || el.outerHTML; 
        }
    }
    return shadowHTML; 
};

export default class ContentTest extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }
        `
    ];
    static properties = {
        test_content : { type: String },
        forPrint : { type: Boolean }
    };


    getContent( content_data){
        switch( content_data.type ){
            case 'worksheet':
                return html`<worksheet-element title='${content_data.title}' ?forPrint='${this.forPrint}' count="${content_data.count}" .placeholder_keys="${content_data.placeholder_keys}" question="${content_data.expression}" .variables="${content_data.variables}" .composite_variables="${content_data.composite_variables}"></worksheet-element>`;
            default:
                return html`<content-element content="${content_data.content}" variables="${content_data.random_variables}"></content-element>`;
        }
    }

    getTmpData(){        
        fetch('tmp_data/addition.parameters.json').then( response => response.json() ).then( data => {
            let content =  data.map( (item) => {
                return this.getContent(item);
            });
            this.test_content = [content, this.test_content];
        });
        
    }

    constructor() {
        super();
        this.getTmpData();
        this.test_content = test_content;
        this.forPrint = false;
    }
    downloadPDF(){
        this.forPrint = !this.forPrint;
        this.shadowRoot.querySelectorAll('worksheet-element').forEach( (item) => {
            item.forPrint = this.forPrint;
        });
    }
    
    pdfDoc(){
        let parsedDom = parseDOM(this.shadowRoot.querySelector('worksheet-element').shadowRoot);
        console.log('pdfDoc:',parsedDom);

        //html2pdf().from(this.shadowRoot.querySelector('worksheet-element')).save();
    }
    render () {
        return [html`<button @click="${this.downloadPDF}">for Print</button><button @click='${this.pdfDoc}'>pdf</button>`,this.test_content]; ;
    }
}
window.customElements.define('content-test', ContentTest);