import { html, css } from 'lit';
import BaseInput from './base-input';
//https://gitlab.com/borrey/input-types/-/tree/master?ref_type=heads


class BaseTool{
    constructor(){}
    static createTmpCanvas(original){
        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = original.width;
        tmpCanvas.height = original.height;
        return tmpCanvas;
    }
    static draw( context, startCoordinates, updateCoordinates, endCoordinates ){
        const tmpCanvas = this.createTmpCanvas(context.canvas);
        const tmpContext = tmpCanvas.getContext('2d');
        this.start( tmpContext, startCoordinates);
        updateCoordinates.forEach( coordinates=>{ this.update(tmpContext, coordinates)});
        this.finish( tmpContext, endCoordinates );
        return tmpCanvas;
    }
    static start( context, coordinates ){
        context.beginPath();
        context.moveTo( coordinates.x, coordinates.y );
    }
    static update( context, coordinates){}
    static finish( context, coordinates ){
        this.update(context, coordinates);
        context.closePath();
    }
    static get title(){ return ''; }
}
class PenTool extends BaseTool{
    constructor(){ super(); }
    static update( context, coordinates){
        context.lineTo( coordinates.x, coordinates.y );
        context.stroke();
    }
    static get title(){ return 'pen'; }
}
class LineTool extends PenTool{
    constructor(){ super(); }
    static draw( context, startCoordinates, updateCoordinates, endCoordinates ){
        const tmpCanvas = this.createTmpCanvas(context.canvas);
        const tmpContext = tmpCanvas.getContext('2d');
        this.start( tmpContext, startCoordinates);
        this.finish( tmpContext, endCoordinates );
        return tmpCanvas;
    }
    static get title(){ return 'line'; }
}
class CircleTool extends BaseTool{
    constructor(){ super(); }
    static get startAngle(){ return 0; }
    static get endAngle(){ return 2*Math.PI; }
    static get counterClockwise(){ return false; } 
    static draw( context, startCoordinates, updateCoordinates, endCoordinates ){
        const tmpCanvas = this.createTmpCanvas(context.canvas);
        const tmpContext = tmpCanvas.getContext('2d');

        const a = Math.abs(startCoordinates.x - endCoordinates.x);
        const startX = Math.min(startCoordinates.x, endCoordinates.x) + a/2;
        const b = Math.abs(startCoordinates.y - endCoordinates.y);
        const startY = Math.min(startCoordinates.y, endCoordinates.y) + b/2;

        const radius = Math.round(Math.sqrt( a*a+b*b)/2);
        tmpContext.beginPath();
        tmpContext.arc( startX, startY, radius, this.startAngle, this.endAngle, this.counterClockwise );
        tmpContext.stroke();
        tmpContext.closePath();
        return tmpCanvas;
    }
    static get title(){ return 'circle'; }
}
class RectangleTool extends BaseTool{
    constructor(){ super(); }
    static draw( context, startCoordinates, updateCoordinates, endCoordinates ){
        const tmpCanvas = this.createTmpCanvas(context.canvas);
        const tmpContext = tmpCanvas.getContext('2d');
        if(startCoordinates.x !== endCoordinates.x && startCoordinates.y !== endCoordinates.y){
            tmpContext.strokeRect(
                Math.min( startCoordinates.x, endCoordinates.x ),
                Math.min( startCoordinates.y, endCoordinates.y ),
                Math.abs( startCoordinates.x - endCoordinates.x ),
                Math.abs( startCoordinates.y - endCoordinates.y )
            );
        }
        return tmpCanvas;
    }
    static get title(){ return 'rectangle'; }
}

const tools = [
    PenTool,
    LineTool,
    CircleTool,
    RectangleTool
];
const toolsMap = tools.reduce((_toolsMap, tool) => {
    _toolsMap[tool.title] = tool
    return _toolsMap
  }, {});

const getEventCoordinates = function( event ){
    return {
        x : ( event.layerX || event.layerX === 0 ) ? 
        event.layerX : event.offsetX,
        y : ( event.layerY || event.layerY === 0 ) ? 
        event.layerY : event.offsetY
    };
}
export class DrawInput extends BaseInput {
    constructor(){
        super();
        this.label = 'Draw your Answer';
        this.currentActionType = tools[0].title;
        this.currentAction = null;
        this.actionStack = [];
        this.redoStack = [];
        this.width = '300px';
        this.height = '200px';
    }

    
    //createRenderRoot(){ return this; }
    static get is(){ return 'input-draw'; }
    static get title(){ return 'Draw'; }
    static get properties() {
        return {
            actionStack : { type : Array },
            width : {type : String},
            height : {type : String}
        }
    }
    static styles = [
        BaseInput.styles, css`
            :host {
                display: block;
                border: 1px solid black; 
            }
            canvas[name="attempt"]{
                border: 1px solid black; 
            }
            canvas{
                cursor: crosshair;
            }
        `
    ];
    undo(event){
        event.preventDefault();
        event.stopPropagation();
        if( this.actionStack.length ){
            this.redoStack = [...this.redoStack, this.actionStack.pop() ];
            this.actionStack = [...this.actionStack ];
            this.clear();
            this.runActionStack();
        }
    }
    redo(event){
        event.preventDefault();
        event.stopPropagation();
        if( this.redoStack.length ){
            this.actionStack = [...this.actionStack, this.redoStack.pop() ];
            this.redoStack = [...this.redoStack ];
            this.clear();
            this.runActionStack();
        }
    }
    clear(){
        const canvas = this.shadowRoot.querySelector('canvas');
        const context = canvas.getContext('2d');
	    context.clearRect(0, 0, canvas.width, canvas.height);        
    }
    runActionStack(){
        this.actionStack.forEach( action=>{
            this.runAction(action);
        });
    }
    runAction( action ){
        const canvas = this.shadowRoot.querySelector('canvas[name=attempt]');
        const context = canvas.getContext('2d');
        const actionTool = toolsMap[action.type];
        const tmpCanvas = actionTool.draw( context, action.start, action.update, action.end);
        context.drawImage(tmpCanvas, 0, 0);
    }
    getInput(){
        return html`
            <section style='position:relative;'>
                <canvas tabindex="0" width='${this.width}' height='${this.height}' name='attempt' @mousedown='${this._start }' @mousemove='${this._update}' @mouseup='${this._finish}'></canvas>
                <canvas width='${this.width}' height='${this.height}' style='position:absolute; left:0; top:0' name='current_layer' @mousedown='${this._start }' @mousemove='${this._update}' @mouseup='${this._finish}'></canvas>
            </section>    
            <section>
                <button @click='${this.undo}' ?disabled=${this.actionStack.length<=0}>undo</button>
                <button @click='${this.redo}' ?disabled=${this.redoStack.length<=0}>redo</button>
                <select @change='${this._changeTool}' name='tool'>${ tools.map(tool=>html`<option value='${tool.title}' >${tool.title}</option>`) }</select>
            </section>
        `;
    }
    _start(event){
        this.currentAction = { 
            type : this.currentActionType, 
            start : getEventCoordinates(event), 
            update :[], 
            end: null 
        };
    }
    _update(event){
        if( this.currentAction && this.currentAction.update ){
            this.currentAction.update.push(getEventCoordinates(event));    
        }
    }
    _finish(event){
        this.currentAction.end = getEventCoordinates(event);
        this.actionStack = [ ...this.actionStack, this.currentAction ];
        this.runAction(this.currentAction);
        this.currentAction=null;
    }
    _changeTool(event){
        this.currentActionType = event.target.value || tool[0].title;
    }
   
    submit(event){
        event.preventDefault();
        event.stopPropagation();
        const value = this.shadowRoot.querySelector('canvas[name=attempt]').toDataURL('image/jpg');
        this.pushAttempt(value);
    }
    pushAttempt( attempt ){
        this.history_array = [...this.history_array, { display : `<img src='${attempt}'/>`, time : Date.now() }];
    }
}

customElements.define('draw-input', DrawInput);

