import { ContentElement } from './content-element';
import { html, css } from 'lit';

export class QuestionElelement extends ContentElement {
    constructor() {
        super();
        this.placeholder_keys =[];
        this.placeholder = `placeholder_keys='[]' placeholder='1+1'`;
        this.forPrint = false;
    }

    static styles = [
        ContentElement.styles,
        css`
            :host {
                display: block;
                padding: 1rem;
            }
        `
    ];
    static properties = {
        placeholder_keys : { type: Array },
        placeholder : { type: String },
        forPrint : { type: Boolean }
    };

    render() {
        return html`<math-input ?forPrint='${this.forPrint}' .variables='${this.variables}' .placeholder_keys='${this.placeholder_keys}' placeholder='${this.placeholder}'></math-input>`;
    }

}
customElements.define('question-elelement', QuestionElelement);
