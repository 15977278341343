import {LitElement, html, css, unsafeCSS} from 'lit-element';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import katex from 'katex';
import katexcss from 'katex/dist/katex.min.css';
import 'katex/dist/contrib/mhchem.mjs';
import { ComputeEngine } from "@cortex-js/compute-engine";
import renderA11yString from "katex/dist/contrib/render-a11y-string.mjs"

//https://github.com/kisonecat/tikzjax

export default class ContentMath extends LitElement {

    static get properties() {
        return {
            displayMode: { type: Boolean },
            variables : { type : Object },
            exp: {type: String},
            ce : { type : Object },
            chem : { type : Boolean }
        };
    }

    static get styles() {
        return [
            unsafeCSS(katexcss), css`
            :host {
                display: inline-block;
            }
            :host(.displaystyle) {
                display: block;
            }
        `];
    }

    constructor(){
        super();
        this.displayMode = false;
        this.exp = '';
        this.isChem = false;
        this.variables = {};
        this.ce = new ComputeEngine();

    }

    updateVariables( variables ){
        this.variables = variables;
    }

    getVariableExp( exp, variables ){
        if( !exp ){ return; }
        if( !variables || !Object.keys(variables).length || this.chem ){ return exp; }
        let expr = this.ce.parse(exp);     
        expr = expr.subs(this.variables);
        return expr.latex;
    }

    render () {
        return html`
            ${unsafeHTML(katex.renderToString( this.getVariableExp(this.exp, this.variables),{}))}
        `;
    }
}
window.customElements.define('content-math', ContentMath);