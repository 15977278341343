import { LitElement, html, css } from 'lit';

let input_counter = 0;

class BaseInput extends LitElement {
    static styles = [
        css`
            @media print{
                button{
                    display: none;
                }
            }
            :host {
                display: block;
                border: 1px solid black;
                padding: 10px;
            }
            .confetti {
                position: absolute;
                animation: fall 3s linear infinite;
                opacity: 0;
            }
            @keyframes fall {
                0% {
                    transform: translateY(-100px) rotate(0deg);
                    opacity: 1;
                }
                100% {
                    transform: translateY(500px) rotate(360deg);
                    opacity: 0;
                }
            }
            .confetti-polygon {
                left: 10%;
                animation-delay: 0s;
            }

            .confetti-circle {
                left: 50%;
                animation-delay: 1s;
            }
        `
    ];

    static properties = {
        errors : { type: Array },
        input_id : { type: String },
        label : { type: String },
        inputType : { type: String },
        input_value : { type: String },
        history_array : { type: Array }
    };

    constructor() {
        super();
        this.input_id = `input_${input_counter++}`;
        this.label = 'Type your answer here:';
        this.inputType = '';
        this.errors = [];
        this.input_value = '';
        this.history_array = [];
    }

    getInput() {
        return html`
            <input type="text" id="${this.input_id}" name="${this.input_id}" value="${this.input_value}"/>
        `;
    }
    getSubmitButtons(){
        return html`
            <button @click="${this.submit}">Submit Answer</button>
        `;
    }

    celebrate(){
        const confettiContainer = document.createElement('div');
        confettiContainer.classList.add('confetti-container');
        this.shadowRoot.appendChild(confettiContainer);
    
        for (let i = 0; i < 20; i++) {
            const confetti = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            confetti.setAttribute('class', 'confetti');
            confetti.setAttribute('width', '20');
            confetti.setAttribute('height', '80');
            confetti.setAttribute('viewBox', '0 0 23.74 92.52');
            confetti.style.left = `${Math.random() * 100}%`;
            confetti.style.animationDelay = `${Math.random() * 3}s`;
    
            const polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
            polygon.setAttribute('fill', 'rgba(202, 51, 124, 1.00)');
            polygon.setAttribute('points', '17.08 31.49 3.56 29.97 10.22 0 23.74 1.52 17.08 31.49');
            confetti.appendChild(polygon);
            confettiContainer.appendChild(confetti);
        }
    
        for (let i = 0; i < 20; i++) {
            const confetti = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            confetti.setAttribute('class', 'confetti');
            confetti.setAttribute('width', '15');
            confetti.setAttribute('height', '15');
            confetti.setAttribute('viewBox', '0 0 10 10');
            confetti.style.left = `${Math.random() * 100}%`;
            confetti.style.animationDelay = `${Math.random() * 3}s`;
    
            const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            circle.setAttribute('cx', '5');
            circle.setAttribute('cy', '5');
            circle.setAttribute('r', '5');
            circle.setAttribute('fill', 'rgba(99, 217, 234, 1.00)');
            confetti.appendChild(circle);
    
            confettiContainer.appendChild(confetti);
        }
        setTimeout(() => {
            this.shadowRoot.removeChild(confettiContainer);
        }, 3000);
    }

    getLabel(){
        return html`
            <label for="${this.input_id}">${this.label}</label>
        `;
    }
    render() {
        return html`
            ${this.getLabel()}
            <div>${this.errors.map((error) => html`<span class="error">${error}</span>`)}</div≥
            <div>${this.getInput()}</div>
            <div>${this.getSubmitButtons()}</div>
        `;
    }
    submit(){
        const input = this.shadowRoot.querySelector('#input');
        console.log('submitting',this.input_value);
    }
}

export default BaseInput;