import { html, css } from 'lit';
import BaseInput from './base-input';
//https://github.com/mdn/dom-examples/blob/main/web-speech-api/speech-color-changer/script.js
//https://www.google.com/intl/en/chrome/demos/speech.html
//https://mdn.github.io/dom-examples/web-speech-api/speech-color-changer/
//https://gopesh3652.medium.com/building-a-voice-to-text-app-with-javascript-a-step-by-step-guide-9042493bdd63


//if want server: https://github.com/Picovoice 
const two_line = /\n\n/g;
const one_line = /\n/g;
function linebreak(s) {
  return s.replace(two_line, '<p></p>').replace(one_line, '<br>');
}

const first_char = /\S/;
function capitalize(s) {
  return s.replace(first_char, function(m) { return m.toUpperCase(); });
}

export class VoiceInput extends BaseInput {
    static styles = [
        BaseInput.styles,
        css`
            :host {
                display: block;
            }
            textarea { 
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 10px;
                font-size: 16px;
                resize: none;
            }
        `
    ];
    static properties = {
        selectedLanguage: { type: String },
        selectedcCountry: { type: String },
        languages : { type: Object },
        recognizing : { type: Boolean },
        browserSupportsSpeechRecognition : { type: Boolean }
    };

    constructor() {
        super();
        this.selectedLanguage = 'Français';
        this.selectedcCountry = 'fr-FR';
        this.label = 'Hold the "Press to Speak" button and speak into the microphone.';
        this.languages = {
            'English' : {   'en-CA' : 'Canada', 'en-GB' : 'United Kingdom', 'en-US' : 'United States' },
            'Español' : {   'es-AR' :'Argentina', 'es-BO' : 'Bolivia', 'es-CL': 'Chile', 'es-CO' : 'Colombia', 'es-CR': 'Costa Rica', 'es-EC': 'Ecuador',
                            'es-SV' : 'El Salvador', 'es-ES' : 'España','es-US' : 'Estados Unidos', 'es-GT' : 'Guatemala', 'es-HN' : 'Honduras', 'es-MX' : 'México',
                            'es-NI' : 'Nicaragua', 'es-PA' : 'Panamá', 'es-PY' : 'Paraguay', 'es-PE' : 'Perú', 'es-PR' : 'Puerto Rico',
                            'es-DO' : 'República Dominicana', 'es-UY' : 'Uruguay', 'es-VE' : 'Venezuela' },
            'Français' :    {'fr-FR' : 'France' , 'fr-CA' : 'Canada' },
             '한국어' : {'ko-KR' : '한국' } 
        };

        this.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        this.browserSupportsSpeechRecognition = this.SpeechRecognition !== undefined;
        if(!this.browserSupportsSpeechRecognition){
            this.errors = [...this.errors, 'Web Speech API is not supported by this browser.'];
        }
    }

    recognitionResult (event) {
        let interim_transcript = '';
        if (typeof(event.results) == 'undefined') {
          this.recognition.onend = null;
          this.recognition.stop();
          this.browserSupportsSpeechRecognition = false;
          return;
        }
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.input_value += event.results[i][0].transcript + ". ";
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }
        this.input_value = capitalize(  this.input_value );
    }
    recognitionError(event){
        if(event.error == 'no-speech'){
            this.errors = [...this.errors, 'No speech was detected.'];
        }
        if(event.error == 'audio-capture'){
            this.errors = [...this.errors, 'No microphone was found. Ensure that a microphone is installed and that microphone settings are configured correctly.'];
        }
        if(event.error == 'not-allowed'){
            if(event.timeStamp - this.start_timestamp < 100) {
                this.errors = [...this.errors, 'Permission to use microphone is blocked.'];
            } else {
                this.errors = [...this.errors, 'Permission to use microphone was denied.'];
            }
        }
    }
    start( event) {
        if(!this.browserSupportsSpeechRecognition){return;}
        this.recognition = new this.SpeechRecognition();
        this.recognition.lang = this.selectedcCountry;
        this.recognition.continuous = true;
        this.recognition.interimResults = true;
        this.recognition.onerror = this.recognitionError.bind(this);
        this.recognition.onresult = this.recognitionResult.bind(this);
        this.recognizing = true;
        this.start_timestamp = event.timeStamp;
        this.recognition.start();
    }
    end() {
        this.recognizing = false;
        this.recognition.stop();
    }
    upgrade(){//assumed to be called only if the browser does not support the Web Speech API checked already
        return html`<p>Web Speech API is not supported by this browser.</p>`;
    }
    updateLanguage(event){
        this.selectedLanguage = event.target.value;
        this.selectedcCountry = Object.keys(this.languages[this.selectedLanguage])[0];
    }
    updateValue(event){
        this.input_value = event.target.value;
        const textarea = this.shadowRoot.querySelector("textarea");
        textarea.innerText =this.input_value;
    }
    getInput() {
        return html`
        <textarea id='${this.id}' name='content' disabled>${this.input_value}</textarea>
        <select name="select_language" @change=${this.updateLanguage}>
            ${ Object.keys(this.languages).map( language => html`<option value="${language}" .selected="${language==this.selectedLanguage}">${language}</option>` )}
        </select>
        <select name="select_country">
            ${ Object.keys(this.languages[this.selectedLanguage]).map( countryCode=> {
                const country = this.languages[this.selectedLanguage][countryCode];
                return html`<option value="${countryCode}" .selected="${countryCode==this.selectedcCountry}">${country}</option>`;
            })}
        </select>        
        <button @mousedown=${this.start} @mouseup=${this.end}>
            Press to Speak
        </button>
        `;
    }
}
customElements.define('voice-input', VoiceInput);
