import { LitElement, html, css } from 'lit';

export class UserElement extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }
        `
    ];
    static properties = {
        name: { type: String },
        email: { type: String },
        username : { type: String }
    };
    
    constructor() {
        super();
        this.username = 'XXXXXXXXX';
        this.name = 'John Doe';
        this.email = '';
    }
 
    handleUserNameChange(event) {
        this.username = event.target.value;
    }
    handleNameChange(event) {
        this.name = event.target.value;
    }
    handleEmailChange(event) {
        this.email = event.target.value;
    }
    updateUser() {
        console.log('Saving user', this.username, this.name, this.email);
    }
    createUser(){
        console.log('Creating user', this.username, this.name, this.email);
    }

    render(){
        return html`
            <div class='update-user'>
                <label for="username">Login Name: <input type="text" id="username" .value=${this.username} @input=${this.handleUserNameChange}></label>
                <label for="name">Name: <input type="text" id="name" .value=${this.name} @input=${this.handleNameChange}></label>
                <label for="email">Email: <input type="email" id="email" .value=${this.email} @input=${this.handleEmailChange}></label>
                <button @click=${this.updateUser}>Update</button>
            </div>

        `;
    }
}
customElements.define('user-element', UserElement);
