import { html, css } from 'lit';
import BaseInput from './base-input';
//import {CodeMirror} from 'codemirror/dist/codemirror';

//import {javascript} from "@codemirror/lang-javascript"

export class CodeInput extends BaseInput {
    static styles = [
        css`
        :host {
            display: block;
        }
        .editor-container {
            height: 400px;
            border: 1px solid #ccc;
        }
        `
    ];

    constructor() {
        super();
        this.editor = null;
    }

    firstUpdated() {
        const editorContainer = this.shadowRoot.querySelector('.editor-container');
        //console.log(CodeMirror);

    }

    getInput() {
        return html`
            <div class="editor-container"></div>
        `;
    }

    render() {
        return html`
            ${this.getInput()}
        `;
    }
}
customElements.define('code-input', CodeInput);
