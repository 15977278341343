import { html, css, unsafeCSS } from 'lit';
import BaseInput from './base-input';

//import jspreadsheet from 'jspreadsheet-ce';
//import style from 'jspreadsheet-ce/dist/jspreadsheet.css';
//import jsuites from 'jspreadsheet-ce/dist/jsuites.min.css';
export class SpreadsheetInput extends BaseInput {
    static styles = [
        BaseInput.styles,
        css`
            :host {
                display: block;
            }
        `
    ];

    static properties = {
        min_dimensions : { type: Array },
        input_value_array : { type: Array }
    };

    constructor() {
        super();
        this.label = 'Fill in the table below:';
        this.input_value_array  = [['1', '2', '3', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '', ''],
        ['', '', '', '', '', '', '', '', '', '2','4']];
        this.min_dimensions = [10,10];
        this.messageHandler = this.handleMessage.bind(this);
        window.addEventListener('message', this.messageHandler);
    }

    getInput() {
        return html`<iframe id="iframe" src="spread-sheet.html" width="100%"></iframe>`;
    }
    firstUpdated() {
        let iframe = this.shadowRoot.querySelector('#iframe');
        const that = this;
        iframe.addEventListener('load', () => { 
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
            iframe.contentWindow.postMessage({ 
                type : 'jspreadsheet',
                min_dimensions : that.min_dimensions,
                input_id : that.input_id,
                data :  that.input_value_array
            }, '*');
        });        
    }
    //window.removeEventListener('message', this.messageHandler);
    disconnectedCallback() {
        window.removeEventListener('message', this.messageHandler);
    }

    handleMessage(event) {
        const target = event.target;
        if(event.data.input_id != this.input_id){ return; }
        if( event.data.type == 'data'){
            this.input_value_array = event.data.data;
            this.input_value = JSON.stringify(event.data.data);
        }
        if(event.data.type =='resize'){
            let iframe = this.shadowRoot.querySelector('#iframe');
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    
        }
        
    }
}
customElements.define('spreadsheet-input', SpreadsheetInput);
