import { html, css } from 'lit';
import { ShortInput } from './short-input';
import { ComputeEngine } from "@cortex-js/compute-engine";
import 'mathlive';
//https://www.npmjs.com/package/mathlive
//https://cortexjs.io/compute-engine/guides/patterns-and-rules/
//https://mathjs.org/docs/expressions/syntax.html

//console.log(ce.parse("a^2+b^3").unknowns);
//studentInput.isSame(expectedAnswer)
export class MathInput extends ShortInput {
    static styles = [
        ShortInput.styles,
        css`
            :host {
                display: block;
            }
            math-field {
                min-width: 100px;
            }
            math-field::part(menu-toggle) {
                display: none;
            }
            math-field span.ML__editablePromptBox {
                min-height : 1em;
                min-width : 1em;
            }
        `
    ];
    static properties = {
        variables : { type : Object},
        placeholder : {type: String},
        placeholder_keys : { type : Array},
        forPrint : { type : Boolean }
    };

    constructor() {
        super();
        this.label = 'Math Input';
        this.placeholder = '';
        this.placeholder_keys = [];
        this.variables = {};
        this.ce = new ComputeEngine();
        this.forPrint = false;
    }

    checkAnswer( answer) {
        let expr = this.ce.parse(this.placeholder);  
        expr = expr.subs(this.variables);
        expr = expr.subs(answer);
        let solved = expr.evaluate();
        switch( solved.type.type ){
            case 'boolean':
                console.log(solved.type.type, solved.toLatex(), solved);
                this.celebrate();
                break;
            case 'number':
                console.log(solved.type.type, solved.json);
                break;
            case 'string':
                console.log(solved.type.type, solved.json);
                break;
            default:
                console.log(solved.type.type, solved.json);
                break;
        }
    }

    submit() {
        const mf = this.shadowRoot.querySelector('math-field');
        if(this.placeholder_keys.length){
            const answers = {};
            this.placeholder_keys.forEach( key => {
                answers[key] = mf.getPromptValue(key);
            });
            this.checkAnswer(answers);
        }else{
            this.input_value = mf.value;
            console.log('submit', mf.value );
        }
    }
    firstUpdated() {
        if(this.shadowRoot.querySelector('math-field')){
            this.shadowRoot.querySelector('math-field').menuItems = [];
        }
        
    }
    getInput() {
        if(this.forPrint){
            return html`<content-math exp="${this.getVariableExp(this.placeholder, this.variables, this.placeholder_keys, this.forPrint)}"></content-math>`;
        }
        return html`
            <math-field ?readonly="${this.placeholder_keys.length}" >${this.getVariableExp(this.placeholder, this.variables, this.placeholder_keys, this.forPrint)}</math-field>
        `;
    }
    getLabel(){
        if(this.forPrint){
            return html``;
        }
        return html`
            <label for="${this.input_id}">${this.label}</label>
        `;
    }
    

    getSubmitButtons(){
        if(this.forPrint){
            return html``;
        }
        if(this.placeholder_keys.length){
            return html`
                <button @click="${this.submit}">Check Answer</button>
            `;
        }
        return html`
            <button @click="${this.submit}">Submit Answer</button>
        `;
    }

    getVariableExp( exp, variables, placeholder_keys, forPrint ){
        if( !exp ){ return ''; }
        if( !variables || !Object.keys(variables).length ){ return exp; }
        
        let expr = this.ce.parse(exp, {canonical: false});  
       
        const replace_variables = {};
        Object.keys(variables).filter( _var=>{
            return !placeholder_keys.includes(_var);
        }).forEach( key => {
            replace_variables[key] = variables[key];
        });
        

        expr = expr.subs(replace_variables, {canonical: false});
        
        if( placeholder_keys.length < 1 ){
            return expr.latex;
        }
        let obj_var = {};
        placeholder_keys.forEach( key => {
            obj_var[key] = `placeholder_${key}`;
        });
        expr = expr.subs( obj_var , {canonical: false});

        let sub_expr = expr.latex;
        placeholder_keys.forEach( key =>{
            sub_expr = forPrint ? sub_expr.replace(`\\mathrm{placeholder_{${key}}}`, `\\underline{\\hskip2em}`) : sub_expr.replace(`\\mathrm{placeholder_{${key}}}`, `\\placeholder\[${key}\]\{\}`);
        });
        return sub_expr;
    }
}
customElements.define('math-input', MathInput);