import { LitElement, html, css } from 'lit';
import { ComputeEngine } from "@cortex-js/compute-engine";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import './content-math';

export class ContentElement extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }
        `
    ];

    static properties = {
        content : { type: String },
        variables : { type: Object }
    };

    constructor() {
        super();
        this.content='Hello World';
        this.variables = {};
    }

    
    render() {
        return html`${unsafeHTML(this.content)} `;
    }

}
customElements.define('content-element', ContentElement);
