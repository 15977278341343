import { ComputeEngine } from "@cortex-js/compute-engine";

/*
let expr = ce.parse('\\sqrt{\\frac{y}{x+1}}');
console.log(expr.json);

expr = expr.subs({x: 3, y:2});

console.log("Substitute x -> 3\n", expr.latex);
console.log("Numerical Evaluation:", expr.evaluate(), expr.N());
*/

class Variable{
    constructor( name = 'x'){
        this.default = null;
        this.variable_name = name;
    }

    replaceExpression( expression ){
        const regex = new RegExp(`\\b${this.variable_name}\\b`, 'g');
        return expression.replace(regex, this.default);
    }
}

export class RandomNumber extends Variable{
    constructor( name = 'x', min = 0, max = 10, default_value = 5, excludes = [] ){
        super( name );
        this.min = min;
        this.max = max;
        this.default = default_value;
        this.excludes = excludes;
    }
}

export class RandomReal extends RandomNumber{
    constructor( name = 'x', min = 0, max = 10, default_value = 5, excludes = []){
        super( name, min, max, default_value, excludes );
    }
    randomize(){
        return Math.random() * (this.max - this.min) + this.min;
    }
}

export class RandomInteger extends RandomNumber{
    constructor( name = 'x', min = 0, max = 10, default_value = 5, excludes = []){ 
        super( name, min, max, default_value, excludes );
    }
    randomize(){
        let random_int;
        do{
            random_int = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
        }while( this.excludes.includes(random_int)); 
        return random_int;
    }
}

export class RandomRational extends RandomNumber{
    constructor( name = 'x', min = 1/100, max = 1, default_value = '\\frac{1}{4}', excludes = [ 1 ], min_denominator = 1, max_denominator = 10, min_numerator = 1, max_numerator = 10){
        super( name, min, max, default_value, excludes);
        this.min_denominator = min_denominator;
        this.max_denominator = max_denominator;
        this.min_numerator = min_numerator;
        this.max_numerator = max_numerator;
    }
    randomize(){
        let numerator, denominator, rationalNumber;
        do {
            numerator = Math.floor(Math.random() * (this.max_numerator - this.min_numerator + 1)) + this.min_numerator;
            denominator = Math.floor(Math.random() * (this.max_numerator - this.min_denominator + 1)) + this.min_denominator;
            rationalNumber = numerator / denominator;
        } while (this.excludes.includes(rationalNumber) || denominator===0 || Number.isInteger( rationalNumber ) || rationalNumber < this.min || rationalNumber > this.max );  // Check if the rational number is in the exclude list
        return `\\frac{${numerator}}{${denominator}}`;
    }
}

export class RandomElement extends Variable{
    constructor(){
        super();
        this.elements = [];
        this.default = null;
    }
    randomize(){
        const index = Math.floor(Math.random() * this.elements.length);
        return this.elements[index];
    }
}

export class CompositeVariable extends Variable{
    constructor( name = 'x', expression= '1'){
        super( name );
        
        this.expression = expression;
        this.ce = new ComputeEngine();
    }
    randomize( random_set ){
        let composite_expression = this.ce.parse(this.expression);
        composite_expression = composite_expression.subs( random_set );
        return composite_expression.evaluate().toLatex();
    }
}

export class RandomVariableSet{
    constructor( variables = {}, composite_varaiables = []){
        this.variables = variables;
        this.composite_varaiables = composite_varaiables;
    }

    addVariable( variable ){
        this.variables[variable.variable_name] = variable;
    }

    createVariableSet( variable_params ={}, composite_varaiables = [] ){
        this.variables = {};

        Object.keys(variable_params).forEach((key)=>{
            switch( variable_params[key].type ){
                case 'randominteger':
                    this.variables[key] = new RandomInteger(key, variable_params[key].min, variable_params[key].max, variable_params[key].default, variable_params[key].excludes);
                    break;
                case 'randomreal':
                    this.variables[key] = new RandomReal(key, variable_params[key].min, variable_params[key].max, variable_params[key].default, variable_params[key].excludes);
                    break;
                case 'randomrational':
                    this.variables[key] = new RandomRational(key, variable_params[key].min, variable_params[key].max, variable_params[key].default, variable_params[key].excludes, variable_params[key].min_denominator, variable_params[key].max_denominator, variable_params[key].min_numerator, variable_params[key].max_numerator);
                    break;
                case 'randomelement':
                    this.variables[key] = new RandomElement(key, variable_params[key].elements, variable_params[key].default);
                    break;
                default:
                    throw new Error(`Unknown variable type ${variable_params[key].type}`);

            }
        });
        
        this.composite_varaiables = composite_varaiables.map( (composite_variable)=>{ 
            return new CompositeVariable(composite_variable.variable_name, composite_variable.expression);
        });
    }

    randomValues(){
        const random_values = {};
        Object.keys(this.variables).forEach((key)=>{
            random_values[key] = this.variables[key].randomize();
        });
        this.composite_varaiables.forEach((composite_variable)=>{
            random_values[composite_variable.variable_name] = composite_variable.randomize(random_values);
        });
        return random_values;
    }
    randomize( count = 1 ){
        if( count < 1){ return; }

        const uniqueSets = new Set();
        const results = [];
        let fail_cnt = 0;
        while (results.length < count) {
            const currentSet = this.randomValues();
            const setString = JSON.stringify(currentSet);

            if (!uniqueSets.has(setString)) {
                uniqueSets.add(setString);
                results.push(currentSet);
            }else{
                fail_cnt++;
            }
            if( fail_cnt > 3 && fail_cnt > 0.1*count){
                throw new Error(`Failed to generate unique sets for the given count of ${count}.`);
            }
        }
        
        return results;
    }
} 