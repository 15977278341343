import { LitElement, html, css } from 'lit';
//import '@planktimerr/tikzjax';
//\overline{\smash{)}#1}

document.addEventListener('tikzjax-load-finished', function(e) {
	console.log('tikzjax-load-finished',e);
	
});

export class LatexImage extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }
        `
    ];

    static properties = {
        code: { type: String },
    }

    constructor() {
        super();
        this.code = `
            \\begin{tikzpicture}
                \\draw (0,0) circle (1in);
            \\end{tikzpicture}
        `;
    }



    updateMath( newValue ) {
        const s = document.createElement('script');
        s.setAttribute('type','text/tikz');
        s.setAttribute('data-show-console','true');
        s.textContent = `${newValue}`;
        //const output = document.querySelector('footer');
        const output = this.shadowRoot.querySelector('#output');
        output.innerHTML = '';
        output.appendChild(s);
        console.log('updateMath:',newValue);
    }

    firstUpdated() {
        this.updateMath(this.code);
    }

    render() {
        return html`
            <div id="output"></div>
        `;
    }
}
customElements.define('latex-image', LatexImage);
