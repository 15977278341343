import { LitElement,html, css } from 'lit';
import './voice-input';
import './spreadsheet-input';
import './choice-input';
import './draw-input';
import './short-input';
import './math-input';
//import './long-input';
import './code-input';

export class InputTest extends LitElement {
    static properties = {
        value: { type: String }
    };
    static styles = css`
        :host {
            display: block;
        }
    `;
    constructor() {
        super();
        this.value = '';
    }
    
    render() {
        return html`
            <spreadsheet-input></spreadsheet-input>
            <voice-input></voice-input>
            <choice-input></choice-input>
            <choice-input choiceType="MS" ></choice-input>
            <choice-input choiceType="MC" ></choice-input>
            <draw-input></draw-input>
            <short-input></short-input>
            <math-input></math-input>
            <math-input placeholder=' \\placeholder[answer2]{?} (x+1)(x-1) = \\placeholder[answer]{?}'></math-input>
            <long-input></long-input>
        `;
    }
}

//Multiple Choice
//Multiple Select (multiple answer)
//True of False
//Essay
//Short Answer
//Fill in the Blank
//Matching
//Ordering
//Numeric
//File Upload
//Audio Upload
//Video Upload
//Image Upload
//Canvas (drawing)
//Code (programming)
//Math 
//Chemistry
//Table (spreadsheet)
//Voice Input

customElements.define('input-test', InputTest);
